import { Plugin } from 'vue';
import { TinyEmitter } from 'tiny-emitter';

interface Events
{
    $on(eventName: string, callback: (...args: any[]) => void): void;
    $once(eventName: string, callback: (...args: any[]) => void): void;
    $off(eventName: string, callback: (...args: any[]) => void): void;
    $emit(eventName: string, ...args: any[]): void;
}

export class EventsHelper implements Events
{
    private emitter: TinyEmitter;

    public constructor(emitter: any)
    {
        this.emitter = emitter;
    }

    public $on(eventName: string, callback: (...args: any[]) => void): void
    {
        this.emitter.on(eventName, callback);
    }

    public $once(eventName: string, callback: (...args: any[]) => void): void
    {
        this.emitter.once(eventName, callback);
    }

    public $off(eventName: string, callback: (...args: any[]) => void): void
    {
        this.emitter.off(eventName, callback);
    }

    public $emit(eventName: string, ...args: any[]): void
    {
        this.emitter.emit(eventName, ...args);
    }
}

const EventsPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;
        const emitter = new TinyEmitter();

        vue.$events = new EventsHelper(emitter);
    }
};

export default EventsPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $events: Events;
    }
}
