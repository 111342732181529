import { createApp } from 'vue';

// Store
import store from './store';

// Router
import router from './router';

// Bootstrap
import logging from '@/plugins/logging';
import axios from '@/plugins/axios';
import config from '@/plugins/config';
import localization from '@/plugins/localization';
import datetime from '@/plugins/datetime';
import events from '@/plugins/events';
import filters from '@/plugins/filters';
import media from '@/plugins/media';
import directives from '@/plugins/directives';
import mixins from '@/plugins/mixins';
import permissions from '@/plugins/permissions';
import alerts from '@/plugins/alerts';
import progress from '@/plugins/progress';
import sitemap from '@/plugins/sitemap';
import auth, { adminAccessPolicy } from '@/plugins/auth';
import signalr from '@/plugins/signalr';
import components from '@/plugins/components';
import OneSignalPlugin from '@/plugins/oneSignal';
import * as Sentry from "@sentry/vue";

import "@coreui/coreui-pro/scss/coreui.scss";

// // Menu
import menu from '@/settings/sitemap';

// // Application
import App from './views/App.vue';

export const app = createApp(App);

if (import.meta.env.VITE_SENTRY_ENV !== 'local')
    Sentry.init({
        app: app,
        environment: import.meta.env.VITE_SENTRY_ENV,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [import.meta.env.VITE_APP_PUBLIC_URL],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            })
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

app.use(store);
app.use(router);
app.use(logging);
app.use(axios);
app.use(config);
app.use(localization);
app.use(datetime);
app.use(events);
app.use(filters, { router, store });
app.use(media);
app.use(directives);
app.use(mixins);
app.use(permissions);
app.use(alerts);
app.use(progress, {
    color: '#14e65a',
    failedColor: 'red',
    thickness: '8px',
    autoFinish: false,
    autoRevert: false,
    inverse: false
});
app.use(sitemap, {
    sitemap: menu
});
app.use(auth, {
    store: store,
    routes: {
        homePage: { path: '/' },
        loginPage: { path: '/auth/login' },
        forbidden: { path: '/error/403' },
        notFound: { path: '/error/404' },
        changePassword: { name: 'core-account-password' }
    },
    defaultPolicy: adminAccessPolicy
});
app.use(signalr, {
    endpoint: `${import.meta.env.VITE_APP_PUBLIC_URL}/hubs`
});
app.use(OneSignalPlugin);
app.use(components);

export const root = app.mount('#app');
