<template>
    <filemanager :modal="false"></filemanager>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'core-files'
})
export default class Files extends Vue
{
}
</script>
