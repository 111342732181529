import { Plugin } from 'vue';
import i18n from '@/settings/i18n';

const ConfigPlugin: Plugin =
{
    install(app, options)
    {
        app.config.globalProperties.$config = (name: string, throwError: boolean = false): Promise<any> =>
        {
            const config = { ...i18n };

            return new Promise((resolve, reject) =>
            {
                resolve(config[name] || {});
            });
        };
    }
};

export default ConfigPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $config(name: string, throwError: boolean): Promise<any>;
    }
}
